import { config as fontAwesomeConfig } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import "focus-visible"
import { graphql, StaticQuery } from "gatsby"
import React, { Fragment } from "react"
import Helmet from "react-helmet"
import * as store2 from "store2"
import styled, { ThemeProvider } from "styled-components"
import "typeface-open-sans"
import "typeface-roboto-mono"
import "typeface-roboto-slab"
import courseMetaData from "../../course-metadata.json"
import ContentArea from "../components/ContentArea"
import Footer from "../components/Footer"
import ScrollArrow from "../components/ScrollToTop"
import "../i18n"
import { canDoResearch } from "../services/moocfi"
import { blueTheme, defaultTheme, indigoTheme } from "../theme"
import Pheromones from "../util/pheromones"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"
import "./reboot.css"
import "./remark.css"
import "./theme.css"

fontAwesomeConfig.autoAddCss = false

const layoutQuery = graphql`
  query {
    title: site {
      siteMetadata {
        title
        theme
      }
    }
  }
`

const themeArray = {
  blueTheme: blueTheme,
  indigoTheme: indigoTheme,
  defaultTheme: defaultTheme,
}

const Wrapper = styled.div`
  ${(props) =>
    props.mobileMenuOpen &&
    `
    height: 100vh;
    overflow: hidden;
  `}
`

class Layout extends React.Component {
  state = {
    mobileMenuOpen: false,
  }

  componentDidMount() {
    const user = store2.get("tmc.user")
    if (typeof window !== "undefined" && user) {
      if (canDoResearch()) {
        setTimeout(() => {
          this.removePheromones = Pheromones.init({
            apiUrl: "https://pheromones.mooc.fi/",
            username: user.username,
            submitAfter: 20,
          })
        }, 1000)
      }
    }
  }

  componentWillUnmount() {
    if (
      typeof window === "undefined" ||
      typeof this.removePheromones === "undefined"
    ) {
      return
    }
    this.removePheromones()
    this.removePheromones = undefined
  }

  toggleMobileMenu = () => {
    this.setState((prev) => {
      return {
        mobileMenuOpen: !prev.mobileMenuOpen,
      }
    })
  }

  render() {
    const { children } = this.props

    return (
      <Fragment>
        {" "}
        <StaticQuery
          query={layoutQuery}
          render={(data) => {
            const siteTitle = data.title.siteMetadata.title
            const derivedTheme = data.title.siteMetadata.theme
            const theme = themeArray[derivedTheme] || defaultTheme
            /*             let selectedTheme
            for (const [key, value] of Object.entries(themeArray)) {
              if (key === derivedTheme) {
                selectedTheme = value
              }
            } */

            return (
              <ThemeProvider theme={theme}>
                <Wrapper mobileMenuOpen={this.state.mobileMenuOpen}>
                  <Helmet
                    defaultTitle={siteTitle}
                    titleTemplate={`%s - ${siteTitle}`}
                    meta={[
                      {
                        name: "description",
                        content:
                          "Verkkokurssi siitä miten leipä löytää pöytäämme, varallisuus maailmassa jakautuu ja maailman suuret ongelmat syntyvät ja voidaan myös ratkaista. Kurssi on Helsingin taloustieteellisen tutkijakoulun Helsinki GSE tutkijoiden, työntekijöiden sekä Helsingin yliopiston tietojenkäsittelytieteen osaston valmistama taloustieteen kurssi.",
                      },
                      {
                        name: "keywords",
                        content:
                          "taloustiede, economics, MOOC, 2021, taloustieteen kurssi, avoin, ilmainen, helsingin yliopisto, aalto yliopisto",
                      },
                    ]}
                  />
                  <ContentArea mobileMenuOpen={this.state.mobileMenuOpen}>
                    {children}
                  </ContentArea>
                  <ScrollArrow />
                  <Footer />
                </Wrapper>
              </ThemeProvider>
            )
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(courseMetaData) }}
        />
      </Fragment>
    )
  }
}

export default withSimpleErrorBoundary(Layout)
