import React from "react"
import styled from "styled-components"

import logo from "../images/Hanken_logo.png"
import MoocFi from "../images/moocfi.svg"
import UHLogo from "../images/uh_without_background.svg"
import HGSE from "../images/HGSE-logo.png"
import AaltoLogo from "../images/aalto_without_background.svg"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"
import { respond } from "../_respond"

const Wrapper = styled.div`
  border: 2.4px solid rgba(35, 31, 32, 0.8);
  margin: 5rem;
  display: grid;
  background: white;
  grid-template-columns: 1fr 0.5fr;
  padding: 4.5rem;
  color: #231f20;

  ${respond.mobile`
  grid-template-columns: 1fr;
  margin: 5rem 1rem;
  padding: 2.5rem;
  `}

  ${respond.xs`
  grid-template-columns: 1fr;
`}

  ${respond.sm`
  grid-template-columns: 1fr;
  `}

  ${respond.md`
  grid-template-columns: 1fr .5fr;
  `}

  ${respond.lg`
  grid-template-columns: 1fr .5fr;
  `}

  div {
    width: 100%;

    span {
      display: block;
      font-family: "Lato";
      font-size: 18px;
      width: 80%;
      color: rgba(35, 31, 32, 0.9);

      ${respond.mobile`
      width: 100%;
      `}
    }

    img {
      margin-bottom: 1rem;
    }
  }

  div:last-of-type {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, auto));
    column-gap: 8px;
    justify-content: end;

    ${respond.mobile`
    margin-top: 1rem;
    justify-content: start;
    `}
    ${respond.xs`
    margin-top: 1rem;
    justify-content: start;
    `}
    ${respond.sm`
    margin-top: 1rem;
    justify-content: start;
    `}
    ${respond.md`
    justify-content: end;
    `}
  }
`

const LogoImg = styled.img`
  width: 6.5em;
  height: 6.5em;
  margin-bottom: 8px !important;
  background: rgba(35, 31, 32);
`

const Footer = () => (
  <Wrapper>
    <div>
      <img
        src={HGSE}
        width="300px"
        alt="Helsinki Graduate School of Economicsin tunnus"
      />
      <span>
        Taloustieteen keskus Helsinki GSE on Aalto-yliopiston, Hankenin ja
        Helsingin yliopiston yhteinen taloustieteen huippuyksikkö, jonka tehtävä
        on kouluttaa, tuottaa korkeatasoista tutkimusta ja hyödyttää
        yhteiskuntaa – niin kansallisesti kuin kansainvälisesti.
      </span>
    </div>

    <div>
      <LogoImg
        src={UHLogo}
        alt="Helsingin yliopiston tunnus"
        style={{ padding: "1rem" }}
      />
      <LogoImg
        src={AaltoLogo}
        alt="Aalto yliopiston tunnus"
        style={{ padding: "1rem" }}
      />
      <LogoImg
        src={MoocFi}
        alt="Massiiviset avoimet verkkokurssit tunnus"
        style={{ padding: "1.8rem" }}
      />
      <LogoImg
        src={logo}
        alt="Hankenin kauppakorkeakoulun tunnus"
        style={{ padding: ".8rem" }}
      />
    </div>
  </Wrapper>
)

export default withSimpleErrorBoundary(Footer)
