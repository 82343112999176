import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import Arrow from "../assets/arrow.svg"

const fadeIn = keyframes`
0% { opacity: 0 }
100% { opacity: 0.5; }
`

const ScrollTop = styled.div`
  position: fixed;
  width: 50px;
  bottom: 70px;
  right: 30px;
  align-items: center;
  height: 50px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: ${fadeIn} 0.3s ease-in-out;
  transition: opacity 0.4s;
  opacity: 0.9;
  border-radius: 50px;
  background: #1c1c1c;

  &:hover {
    opacity: 1;
  }
`

const StyledIcon = styled(Arrow)`
  width: 15px;
  fill: white;
`

const ScrollTopArrow = () => {
  const [showScroll, setShowScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop)
    return function cleanup() {
      window.removeEventListener("scroll", checkScrollTop)
    }
  })

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const show = showScroll ? "flex" : "none"

  return (
    <ScrollTop onClick={scrollTop} style={{ display: `${show}` }}>
      <StyledIcon />
    </ScrollTop>
  )
}

export default ScrollTopArrow
